<template>
	<form @submit.prevent="saveItem">
		<ValidationObserver>
			<b-card title="Banner Fitur Kamu Detail">
				<b-col cols="12">
					<div class="form-group form-row">
						<label class="col-12 col-md-4 col-form-label">Nama Banner*</label>
						<div class="col">
							<validation-provider
								name="name"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Nama Banner" 
									v-model="formBanner.name"
								>
								<small
									v-for="(validation, index) in validations.name"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
					</div>
					<div class="form-group form-row">
						<label class="col-12 col-md-4 col-form-label">Image(Required)</label>
						<div class="col">
							<b-form-file
								placeholder="Choose a file or drop it here..."
								drop-placeholder="Drop file here..."
								@change="changePhoto($event)"
							/>
							<small
								v-for="(validation, key) in validations.images"
								:key="`errorName${key}`"
								class="text-danger"
							>{{ validation }}</small>
							<p class="images__hint"><i>Image Size: 656 x 274 pixel</i></p>
							<div
								v-if="formBanner.images"
								class="mt-1 relative"
							>
								<img
									:src="image_url"
									class="img-fluid"
								>
								<b-button
									variant="gradient-danger"
									class="btn-icon rounded-circle"
									@click="cancelPhoto"
								>
									<feather-icon icon="XIcon" />
								</b-button>
							</div>
						</div>
					</div>
					<div class="form-group form-row align-items-center">
						<label class="col-12 col-md-4 col-form-label">Date</label>
						<div class="col">
							<div class="form-row">
								<div class="form-group col-md-6">
									<label>Start Date:*</label>
									<validation-provider
										name="start_date"
										rules="required"
									>
										<flat-pickr
											v-model="formBanner.start_date"
											class="form-control"
											:config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
										/>
										<small
											v-for="(validation, index) in validations.start_date"
											:key="`errorName${index}`"
											class="text-danger"
										>{{ validation }}</small>
									</validation-provider>
								</div>
								<div class="form-group col-md-6">
									<label>End Date:*</label>
									<validation-provider
										name="end_date"
										rules="required"
									>
										<flat-pickr
											v-model="formBanner.end_date"
											class="form-control"
											:config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
										/>
										<small
											v-for="(validation, index) in validations.end_date"
											:key="`errorName${index}`"
											class="text-danger"
										>{{ validation }}</small>
									</validation-provider>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group form-row">
						<label class="col-12 col-md-4 col-form-label">Partner*</label>
						<div class="col">
							<validation-provider
								name="name"
								rules="required"
								v-slot="{ errors }"
							>
								<v-select
									v-model="partnerSelected"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:state="errors.length > 0 ? false:null"
									label="name"
									multiple
									placeholder="-- Pilih Partner --"
									:options="partner"
								/>
								<small
									v-for="(validation, index) in validations.partner"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
					</div>
					<div class="form-group form-row">
						<label class="col-12 col-md-4 col-form-label">Type*</label>
						<div class="col">
							<select class="form-control" placeholder="-- Pilih Type --" v-model="formBanner.type">
								<option value="">-- Pilih Type --</option>
								<option value="page">Page</option>
								<option value="url">URL</option>
								<option value="service">Service</option>
								<option value="sales">Sales</option>
							</select>
							<div class="mt-1" v-if="formBanner.type == 'page'">
								<v-select
									v-model="selected"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									label="title"
									placeholder="-- Pilih Pages --"
									:options="pages"
								/>
							</div>
							<div class="mt-1" v-if="formBanner.type == 'url'">
								<div class="input-group">
									<input 
										type="text" 
										v-model="formBanner.url"
										class="form-control" 
										placeholder="https://"
									>
								</div>
							</div>
							<div class="mt-1" v-if="formBanner.type == 'service'">
								<v-select
									v-model="selected"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									label="name"
									placeholder="-- Pilih Service --"
									:options="services"
								/>
							</div>
						</div>
					</div>
					<div class="form-group form-row" v-if="formBanner.type == 'sales'">
						<label class="col-12 col-md-4 col-form-label">Price</label>
						<div class="col">
							<input 
								type="number"
								class="form-control"
								placeholder="Price" 
								v-model="priceInput"
							>
						</div>
					</div>
				</b-col>
			</b-card>
			<b-card v-if="formBanner.type == 'sales'" title="List Feature">
				<div class="text-right">
					<b-button
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
						@click="addFeatures"
					>
						Add
					</b-button>
				</div>
				<div
					v-for="(feature, index) in inputFeature"
					:key="index"
				>
					<div class="d-flex justify-content-between align-items-center mb-1">
						<b-button
							variant="danger"
							class="btn waves-effect waves-float waves-light btn-danger"
							@click="removeFeatures(index)" v-show="index > 0"
						>
							Delete Features
						</b-button>
					</div>
					<div class="form-group">
						<label>Fitur</label>
						<v-select
							v-model="serviceSelected[index]"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							label="name"
							placeholder="-- Pilih Fitur --"
							:options="services"
							@input="changeService($event, index)"
						/>
						<small
							v-for="(validation, key) in validations['features.' + index + '.uuid']"
							:key="`errorName${key}`"
							class="text-danger"
						>{{ validation }}</small>
					</div>
					<div class="form-group">
						<label>Benefit Count</label>
						<b-form-spinbutton
							id="demo-sb"
							v-model="feature.benefit_count"
							:min="serviceSelected[index].qty"
							max="1000"
							:step="serviceSelected[index].qty"
							:value="feature.qty * serviceSelected[index].qty"
							@change="benefitMultiple($event, index)"
						/>
						<small
							v-for="(validation, key) in validations['features.' + index + '.benefit_count']"
							:key="`errorName${key}`"
							class="text-danger"
						>{{ validation }}</small>
					</div>
				</div>
			</b-card>
			<b-col cols="12" class="p-0 mt-2">
				<template v-if="isLoading">
					<button
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
						disabled
					>
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</button>
				</template>
				<template v-else>
					<button
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
					>
						Save
					</button>
					&nbsp;
					&nbsp;
					<a
						@click="handleBackButton()"
						variant="secondary"
						class="btn waves-effect waves-float waves-light btn-secondary"
					>
						Cancel
					</a>
				</template>
			</b-col>
		</ValidationObserver>
	</form>
</template>

<script>
import {
	BCard, 
	BFormGroup, 
	BFormRadio, 
	BFormFile,
	BRow, 
	BCol,
	BButton,
	BSpinner,
	BFormCheckbox,
	BFormSpinbutton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BButton,
		vSelect,
		BFormFile,
		BFormGroup,
		BFormRadio,
		BSpinner,
		flatPickr,
		BFormCheckbox,
		BFormSpinbutton,
		ValidationProvider,
		ValidationObserver,
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	data() {
		return {
			required,
			isLoading: false,
			formBanner:{
				name: '',
				images: '',
				url: '',
				start_date: null,
				end_date: null,
				redirect_id: '',
				type: '',
				partner:[],
				redirect: null
			},
			inputFeature: [
				{
					uuid: '',
					benefit_count: '',
					qty: '',
				}
			],
			services:[],
			partnerSelected:[],
			pages:[],
			priceInput: '',
			partner: [],
			serviceSelected: [],
			validations: '',
			selected: '',
			image_url: null,
		}
	},

	mounted() {
		this.loadService(),
		this.loadPartner(),
		this.loadPage()
	},

	methods: {
		handleBackButton() {
			history.back()
		},

		async loadPage() {
			this.$http.get('admin/pages')
			.then(response => {
				const permissionData = response.data.data
				permissionData.forEach(element => {
					this.pages.push({
						'uuid': element.uuid,
						'title': element.title
					})
				});
			})
		},

		loadBannerFeature() {
			this.inputFeature.forEach((value) => {
				this.serviceSelected.push({
					'uuid': value.uuid,
					'name': value.name,
					'qty': value.benefit_count / value.qty,
				})
			})
		},

		async loadService() {
			this.$http.get('admin/services?type=all')
			.then(response => {
				const permissionData = response.data.data
				permissionData.forEach(element => {
					this.services.push({
						'uuid': element.uuid,
						'name': element.name,
						'qty': element.qty,
					})
				});
			})
		},

		async loadPartner() {
			this.$http.get('admin/partners')
			.then(response => {
				response.data.data.forEach(element => {
					this.partner.push({'uuid':element.uuid, 'name':element.name})
				});
			})
		},

		changeService(service, index) {
			this.inputFeature[index].qty = 1
			this.inputFeature[index].benefit_count = 1 * service.qty
		},

		benefitMultiple(event, index) {
			this.inputFeature[index].qty = event / this.serviceSelected[index].qty
		},

		cancelPhoto() {
			this.image_url = '',
			this.formBanner.images = ''
		},
		changePhoto(event) {
			this.formBanner.images = event.target.files[0]
			this.showPhoto(event.target.files[0])
		},
		showPhoto(file) {
			const reader = new FileReader()
			reader.onload = e => {
				this.image_url = e.target.result
			}
			reader.readAsDataURL(file)
		},

		addFeatures() {
        	this.inputFeature.push({ 
				uuid: '',
				benefit_count: '',
				qty: '',
			});
			this.serviceSelected.push({ 
				uuid: '',
				name: '',
				qty: '',
			});
		},

		removeFeatures(index) {
			this.inputFeature.splice(index, 1);
		},

		saveItem() {
			this.isLoading = true
			this.formBanner.features = this.inputFeature
			const payload = new FormData()
			this.formBanner.partner_uuids = this.partnerSelected.map(partner => partner.uuid)
			this.formBanner.redirect = {
				'features': this.inputFeature,
				'price' : this.priceInput
			}
			this.selected != null ? this.formBanner.redirect_id = this.selected.uuid : ''
			if (this.formBanner.type == 'url') {
				this.formBanner.redirect_id = ''
				this.formBanner.redirect = null
			} 
			else if (this.formBanner.type == 'sales') {
				this.formBanner.url = ''
			} else {
				this.formBanner.url = ''
				this.formBanner.redirect.price = null
				this.formBanner.redirect = null
			}
			payload.append("name", this.formBanner.name)
			payload.append("start_date", this.formBanner.start_date)
			payload.append("end_date", this.formBanner.end_date)
			payload.append("type", this.formBanner.type)
			payload.append("url", this.formBanner.url)
			payload.append("redirect_id", this.formBanner.redirect_id)
			payload.append("banner_location", 'my-feature')
			if (typeof this.formBanner.images != 'string' && this.formBanner.images != null) {
				payload.append("image", this.formBanner.images)
			}
			if (this.formBanner.partner_uuids) {
				for (let index = 0; index < this.formBanner.partner_uuids.length; index++) {
					const element = this.formBanner.partner_uuids[index];
					payload.append('partner_uuids[]', element)
				}
			}
			if (this.formBanner.redirect !== null || this.formBanner.type == 'sales') {
				payload.append("price", this.formBanner.redirect.price)
				this.formBanner.redirect.features.forEach((features, index) => {
					Object.keys(features).forEach(key => {
						if(key != 'description' || key != 'slug' || key != 'group') {
							if (key == 'uuid') {
								payload.append('features' + '['+ index + '][' + key + ']', this.serviceSelected[index].uuid);
							} else if (key == 'qty') {
								payload.append('features' + '['+ index + '][' + key + ']', features[key]);
							} else if (key == 'benefit_count') {
								payload.append('features' + '['+ index + '][' + key + ']', features[key]);
							}
						}
					});
				});
			}
			this.$http.post(`admin/banners`, payload , {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(response => {
				successNotification(this, 'Success', 'Banner Fitur Kamu Berhasil diedit!')
				this.$router.push({ name: 'banner-your-feature' })
				this.isLoading = false
			}).catch(error => {
				if (error.response.data.meta.messages) {
					errorNotification(this, 'Oops!', error.response.data.meta.messages) 
					this.isLoading = false
				}
				if (error.response.data.meta.validations.name || error.response.data.meta.validations.status || error.response.data.meta.validations.price ) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Images', this.validations.images.join(' '))
					errorNotification(this, 'Nama', this.validations.name.join(' '))
				} else {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Opps!', 'Terjadi kesalahan, Periksa kembali data anda.')
				}
				this.isLoading = false
			})
		},
	},

	async created() {
		await this.loadBannerFeature()
	}

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
	.form-check-inline {
		flex-wrap: wrap;
	}
	.images__hint {
		font-size: 10px;
		margin-bottom: 0;
	}
</style>